<template>
  <header class="header" :class="{ openMenu: isActive }">
    <Desktop
      :header="header"
      :menu="menu"
      :translations="translations"
      :services="services"
      @showFormSearch="toggleForm"
      v-if="desktop === true"
    />
    <Ipad
      :header="header"
      :menu="menu"
      :translations="translations"
      :services="services"
      @showFormSearch="toggleForm"
      v-if="ipad === true"
      @menu-btn="menuBtn"
      @menu-close="menuClose"
    />
    <Mobile
      :header="header"
      :menu="menu"
      :translations="translations"
      :services="services"
      @showFormSearch="toggleForm"
      v-if="mobile === true"
      @menu-btn="menuBtn"
      @menu-close="menuClose"
    />
  </header>
</template>

<script>
import { defineAsyncComponent } from "vue";

const Desktop = defineAsyncComponent(() =>
  import("@/components/Header/Desktop.vue")
);
const Ipad = defineAsyncComponent(() => import("@/components/Header/Ipad.vue"));
const Mobile = defineAsyncComponent(() =>
  import("@/components/Header/Mobile.vue")
);

export default {
  components: {
    Desktop,
    Ipad,
    Mobile,
  },
  props: {
    header: {
      type: Array,
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
    translations: {
      type: Array,
      required: true,
    },
    services: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      window: {
        width: 0,
      },
      desktop: true,
      ipad: false,
      mobile: false,
      isActive: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;

      if (this.window.width >= 1280) {
        (this.desktop = true), (this.ipad = false), (this.mobile = false);
      } else if (this.window.width >= 576 && this.window.width < 1280) {
        (this.desktop = false), (this.ipad = true), (this.mobile = false);
      } else if (this.window.width < 576) {
        (this.desktop = false), (this.ipad = false), (this.mobile = true);
      }
    },
    menuBtn() {
      this.isActive = !this.isActive;
    },
    menuClose() {
      this.isActive = false;
    },
    toggleForm() {
      this.$emit("openForm", "header");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 31;
  background: #1c1c1c;
  box-shadow: 0px 0px 6px #f9b80ecc;
  color: #fff;
  padding: 15px 0;

  &.openMenu {
    z-index: 100;
  }

  @media (min-width: 576px) {
    height: 105px;
  }

  @media (max-width: 575.98px) {
    height: 68px;
  }
}
</style>
